.top-nav{
  background: white;
  height:50px;
  /* display:flex;
  justify-content: space-between;
  align-items: center; */
  padding: 0 50px;
}
.top-nav >.ant-row-flex{
  height: 100%;
}
.ant-layout-header{
  padding: 0;
  height: auto;
}
.ant-dropdown-link .ant-avatar-sm.ant-avatar-icon{
  background: #1890ff;
}
.ant-menu li:first-child{
  margin-top:0;
}
.table-operations {
  margin-bottom: 16px;
}

.table-operations > button {
  margin-right: 8px;
}
 .left-nav .ant-menu-item > a{
   display: inline-block;
 }
.login-form-forgot {
  font-weight: 400;
}
 .mr-10 {
  margin-right: 10px;
}
 .modal-footer-row {
  margin-left: -15px;
  margin-right: -15px;
  padding: 9px 15px 0px;
  border-top: 1px solid #e8e8e8;
}
.modal-footer-row button+button{
  margin-left: 8px;
}
.modal-footer-row.no-border{
  border-top: 0;
}
.text-right{
  text-align: right;
}
.blank-View{
  width: 100%;
  min-height: 200px;
  text-align: center;
  background: white;
  padding: 60px 0;
}
.ant-form-item-label{
  line-height: 1;
}
.ant-input{
    box-shadow: 0 0 0 1px rgba(49,49,93,.03), 0 2px 5px 0 rgba(49,49,93,.1), 0 1px 2px 0 rgba(0,0,0,.08);
    /* height: 28px; */
    border: 1px solid transparent;
}
.ant-layout-sider{
  left: 0;
  min-height: 100vh;
}
.content_container{
  width: 100%;
}
.pagination-wrapper {
  padding: 15px 0px;
  text-align: right;
  position: relative;
  min-height: 55px;
}
.pagination-wrapper ul {
  padding: 0;
  margin: 0;
}
.pagination-wrapper ul li {
  display: inline-block;
  cursor: pointer;
  border-radius: 4px;
  user-select: none;
  min-width: 30px;
  height: 30px;
  line-height: 28px;
  text-align: center;
  margin-right: 8px;
}
.pagination-wrapper.disabled-box li.ant-pagination-item, .pagination-wrapper.disabled-box li.ant-pagination-item a{
  border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
}
.no_colon label:after{
  display: none;
}
.ant-btn-sm{
  height: 28px;
  padding: 0 12px;
}
.ant-form-item{
  margin-bottom: 15px;
}
.full-width{
  width: 100%;
}
.ant-layout{
  min-height: 100vh;
}
.auth-form-row {
  position: relative;
  top: 2.5rem;
  margin: 0 2.5rem;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  -webkit-box-flex: 1;
  -ms-flex: 1 0;
  flex: 1 0;
  min-width: 0;
  height: calc(100vh - 2 * 2.5rem);
  background: #f7f7f7;
  border-radius: 0.375rem;
  font-weight: 400;
  border: 0 solid #fff;
  overflow: auto;
}
.login-title {
  color: #2b2d50;
  margin-bottom: 0.75rem;
  font-weight: 400;
}
.text-center {
  text-align: center !important;
}
.login-sub-title {
  color: #a4abb3;
  font-weight: 400;
  margin-bottom: 1.5rem;
  font-size: 13px;
}
.login-form-wrapper .ant-input{
  height: 42px;
}
.logged-in-container{
  padding: 20px;
}
.login-form-button{
  padding: 0 30px;
  width: 100%;
}
.mb-10{
  margin-bottom: 10px;
}
.mb-15{
  margin-bottom: 15px;
}
.my-5{
  margin-top: 5px;
  margin-bottom: 5px;
}
.ant-spin-nested-loading > div > .ant-spin.fixed-spinner{
  position: fixed;
  max-height: none;

}
.ant-spin-nested-loading > div > .ant-spin.fixed-spinner .ant-spin-dot{
  left: 55%;
}
.ant-table-thead > tr > th, .ant-table-tbody > tr > td{
  padding: 8px 10px;
}
.ant-btn-sm{
  font-size: 13px;
}
.with-hand-cursor  .ant-table-tbody > tr{
  cursor: pointer;
}
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td{
  background: transparent;
}
.funding-accounts-wrapper{
  max-width: 450px;
  margin-top: 10px;
}
.account-heading{
  margin-top: 10px;
  margin-bottom: 5px;
}
.account-heading.inital-charge{
  margin-bottom: 15px;
}
.funding-accounts-wrapper .ant-card{
  box-shadow: 0 0 2px 0 rgba(53,65,83,0.12), 0 2px 4px 0 rgba(53,65,83,0.12);
  border:none;
}
.account-card-inner{
  display: flex;
}
.account-card-icon{
  width: 120px;
  background: #f0f2f5;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius:4px;
  margin-right: 20px;
}
.accounts-card-info{
  width: calc(100% - 100px);
}
.account-card-icon i{
  font-size: 45px;
  color: #9e9e9e;
}
.accounts-card-info .account-name{
  font-weight: 600;
  margin-bottom: 5px;
}
.accounts-card-info .account-bank-name{
  color: rgb(0, 212, 142);
  margin-bottom: 8px;
}
.accounts-card-info .label{
  font-size: 12px;
  color:#9e9e9e;
}
.accounts-card-info .value{
  color: rgba(0, 0, 0, 0.72);
  font-size: 12px;
  padding-left: 1px;
  word-break: break-word;
}
.accounts-card-info p{
  margin-bottom: 5px;
}
.data-modal.ant-modal{
  width: 600px !important;
}
.initial-charge-wrapper .ant-empty-normal{
  margin: 0;
}
.initial-charge-wrapper .ant-card{
  box-shadow: 0 0 2px 0 rgba(53,65,83,0.12), 0 2px 4px 0 rgba(53,65,83,0.12);
  border:none;
  margin-top: 12px;
}
.initial-charge-wrapper.filled .ant-card-body{
  padding: 40px 24px;
}
.receipt_list{
  padding: 0;
  margin: 0;
  list-style: none;
}
.ant-notification-notice-with-icon .ant-notification-notice-message{
  font-size: 14px;
}
.mt-20{
  margin-top: 20px;
}
.ant-modal.small-modal{
  width: 350px !important;
}
.ant-modal.small-modal .ant-select{
  width: 100%;
}
.with-dollar .ant-input-number-input-wrap{
  position: relative;

}
.auto-pay-toggle{
  display: inline-block;
  position: relative;
  top: -2em;
  left: 7em;
}
.with-dollar .ant-input-number-input{
  padding-left: 20px;
}
.with-dollar .ant-input-number-input-wrap:before{
  position: absolute;
  left: 0;
  content: '$';
  padding-left: 5px;
}
.funding-card-wrapper .ant-card-body{
  padding: 20px;
}
.uploaded_file_wrapper{
  position: relative;
  max-width: 50%;
  padding: 9px 40px 8px 17px;
  background: rgba(128, 128, 128, 0.69);
  color: #FFFFFF;
  border-radius: 18px;
  display: inline-block;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}
.uploaded_filename{
  width: 180px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.remove_report_link{
  background-image: url("https://joon-dev.s3.amazonaws.com/images/white_close_icon.png");
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 5px;
  right: 12px;
  width: 25px;
  cursor: pointer;
  background-size: 14px 14px;
  height: 25px;
}
.add_receipt_btn{
  display: block;
  cursor: pointer;
  text-decoration: none;
  padding-bottom: 1px;
  width: 85px;
  color: rgba(0, 0, 0, 0.85);
  border-bottom: 1px solid rgba(0, 0, 0, 0.85);
}
.add_receipt_btn:hover, .add_receipt_btn:focus{
  color: rgba(0, 0, 0, 0.85);
  text-decoration: none;
}
.receipt_upload_wrap span{
  display: block;
}
.mb_10{
  margin-bottom: 10px;
}
.error-wrap{
  word-break: break-word;
}
.copy-btn-wrap{
  width: 160px;
  margin:10px auto;
  text-align: center;
}
.green-text{
  color: #36c09b;
}
.mt-10{
  margin-top: 5px;
}
.error-text{
  color:#f5222d;
  margin-top: 5px;
  font-size: 12px;
}
.ant-table {
  overflow-x: scroll;
}

.download_button {
  padding-left: 0;
}
.invite_bp_list {
  list-style: none;
  padding-left: 0;
}
.invite_bp_list .datepicker {
  float: right;
}
.invite_bp_list li {
  line-height: 36px;
}
