.with-left-nav .app-content {
  padding-left: 60px;
  margin-top: -1px;
  padding-top: 14px;
}

.left-nav a i {
  color: white;
}
.left-nav .ui.vertical.menu {
  background-color: transparent;
  box-shadow: none;
  border-color: transparent;
  margin-top: 0;
}
.left-nav .ui.vertical .item::before {
  background: rgba(255, 255, 255, 0.205);
}
.left-nav .ui.menu .item {
  padding: 10px 0;
  word-wrap: break-word;
}
span.left-nav-text {
  font-size: 10px;
}
.ui.link.menu .item:hover,
.ui.menu .dropdown.item:hover,
.ui.menu .link.item:hover,
.ui.menu a.item:hover {
  cursor: pointer;
  color: #fff;
}
.ui.menu .active.item:hover,
.ui.vertical.menu .active.item:hover {
  color: #fff !important;
}
.ui.table.wrapped {
  word-break: break-word;
}
.hide {
  display: none;
}
.app-version {
  position: absolute;
  left: 0;
  bottom: 70px;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.72);
  left: 11px;
  padding: 2px 6px;
  border-radius: 5px;
}
.app-version span {
  display: block;
  color: rgba(255, 255, 255, 0.72);
  font-size: 12px;
  line-height: 12px;
}
.ver-label {
  letter-spacing: 2px;
}
.left-nav-text-wrap {
  word-wrap: break-word;
}
.white-bg {
  background: var(--color-white);
}
.round-borders-4x {
  border-radius: 4px;
}
.pagination-wrapper {
  padding: 15px 0px;
  text-align: right;
  position: relative;
  min-height: 55px;
}
.total-results-wrap{
  position: absolute;
  left: 20px;
  top: 50%;
  margin-top: -11px;
  font-size: 12px;
}
.result-count{
  font-size: 14px;
  font-weight: bold;
  padding-right: 3px;
}
.full-width{
  width: 100%;
}
.search-bar-style{
  margin-bottom:20px;
}
.ant-layout-header{
  line-height: 0px;
  background: transparent;
}